/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/css/_normalize.css"
import "./src/css/_reset.scss"
import "./src/css/_common.scss"
import "./src/css/_home.scss"
import "./src/css/_about.scss"
import "./src/css/_works.scss"
import "./src/css/_contact.scss"
import "./src/css/_single.scss"
import "./src/css/_single.scss"
import "./src/css/_recruit.scss"
import "./src/css/_404.scss"

import React from 'react';
import { AnimatePresence } from 'framer-motion';
export const wrapPageElement = ({ element }) => (
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

//framer-motion TOPに戻るをオフに
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const transitionDelay = 1000

    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        );
    }
    return false;
};

export const onInitialClientRender = () => {
    sessionStorage.setItem('disp_loading', 'on')
};